<template>
  <div class="app-wrap">
    <h3>安全中心</h3>
    <div class="safe-item">
      <img src="@/assets/images/myFavors/safe1.png">
      <div class="center">
        <h4>修改登录密码</h4>
        <p>
          您可用该密码登录基金交易账户<br>
          请勿泄露登录密码，以防被不法分子利用，造成损失。
        </p>
      </div>
      <div class="right">
        <span
          class="btn1"
          @click="handleResetLoginPW"
        >重置登录密码</span>
        <span
          class="btn2"
          @click="handleUpdateLoginPW"
        >修改登录密码</span>
      </div>
    </div>
    <div class="safe-item">
      <img src="@/assets/images/myFavors/safe2.png">
      <div class="center">
        <h4>修改交易密码</h4>
        <p>
          您可用该密码买卖基金，修改账户信息<br>
          请勿泄露交易密码，以防被不法分子利用，造成损失。
        </p>
      </div>
      <div class="right">
        <span
          class="btn1"
          @click="handleResetTradePW"
        >重置交易密码</span>
        <span
          class="btn2"
          @click="handleUpdateTradePW"
        >修改交易密码</span>
      </div>
    </div>
    <div
      class="safe-item"
      style="border-bottom: 0"
    >
      <img src="@/assets/images/myFavors/safe3.png">
      <div class="center">
        <h4>修改绑定手机</h4>
        <p>
          您可用该手机登录验证基金交易账户<br>
          修改绑定手机需验证原手机，请谨慎修改。
        </p>
      </div>
      <div class="right">
        <span
          class="btn1"
          @click="handleUpdateBindPhone"
        >修改绑定手机</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    handleUpdateLoginPW() {
      this.$router.push({
        name: 'UpdateLoginPW'
      })
    },
    handleResetLoginPW() {
      this.$router.push({
        name: 'ResetPasswordOne'
      })
    },
    handleUpdateTradePW() {
      this.$router.push({
        name: 'UpdateTradePW'
      })
    },
    handleResetTradePW() {
      this.$router.push({
        name: 'ResetTradePW'
      })
    },
    handleUpdateBindPhone() {
      this.$router.push({
        name: 'UpdateBindPhone'
      })
    }
  },
};
</script>

<style scoped lang="less">
@import url('@/assets/css/zc-common.less');
.app-wrap > h3 {
  width: 100%;
  font-weight: bold;
  font-size: 20px;
  color: #1F1F1F;
  border-bottom: 1px solid #EDF1F7;
  padding-bottom: 30px;
  .border-box()
}
.safe-item {
  width: 100%;
  .flex(space-between, center, row);
  .border-box();
  padding: 44px 0;
  border-bottom: 1px solid #EDF1F7;
  &>img {
    width: 213px;
    height: 142px;
  }
  .center {
    width: 340px;
    &>h4 {
      font-weight: normal;
      font-size: 18px;
      color: #1F1F1F;
      margin-bottom: 24px;
    }
    p {
      font-size: 14px;
      color: #1F1F1F;
    }
  }
  .right {
    .flex(center,center, column);
    .btn1 {
      .inline-flex(center, center, row);
      width: 132px;
      height: 36px;
      background: #DEB87B;
      border-radius: 2px;
      color: #FFFFFF;
      font-size: 16px;
      cursor: pointer;
    }
    .btn2 {
      .inline-flex(center, center, row);
      width: 132px;
      height: 36px;
      background: #FFFFFF;
      border: 0.6px solid #CE9B63;
      box-sizing: border-box;
      border-radius: 2px;
      font-size: 16px;
      color: #CE9B63;
      margin-top: 44px;
      cursor: pointer;
    }
  }
}
</style>